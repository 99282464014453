import React from "react"
import { Box, Text, Stack, useColorModeValue, WrapItem } from "@chakra-ui/react"
import { Link } from "gatsby"

export default function JobItem({ title, location, link }) {
  return (
    <Box>
      <Link to="" py={6} width="100%">
        <Box
          bg={useColorModeValue("white", "gray.900")}
          border="1px solid #eee"
          rounded={"md"}
          p={6}
          overflow={"hidden"}
        >
          <Stack>
            <Text
              as="h3"
              color="gray.600"
              fontSize={"2xl"}
              fontFamily={"body"}
              fontWeight="600"
              height="50px"
            >
              {title}
            </Text>
            <Text
              as="p"
              color="gray.600"
              fontSize={"18px"}
              fontFamily={"body"}
              height="50px"
            >
              {location}
            </Text>
            <Text
              color={"green.500"}
              textTransform={"uppercase"}
              fontWeight={800}
              fontSize={"sm"}
              letterSpacing={1.1}
            >
              Apply Now
            </Text>
          </Stack>
        </Box>
      </Link>
    </Box>
  )
}
