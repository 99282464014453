import React, { useEffect } from "react"
import { Container, Box, SimpleGrid } from "@chakra-ui/react"
import JobItem from "./components/jobItem"
import { JOBS } from "../../constants"
import SkeletonJobItem from "./components/skeletonJobItem"

const skeletonJobs = ["Software", "Software", "Software", "Software"]

export default function JobList() {
  const [jobs, setJobs] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getAllData()
  }, [])

  const getAllData = () => {
    setLoading(true)
    setTimeout(() => {
      setJobs(JOBS)
      setLoading(false)
    }, 5000)
  }

  const renderJobs = React.useCallback(() => {
    return jobs.map(job => (
      <JobItem title={job.title} location={job.location} link={job.link} />
    ))
  }, [jobs])

  const renderSkeleton = React.useCallback(() => {
    return skeletonJobs.map(Skeleton => <SkeletonJobItem />)
  }, [skeletonJobs])
  return (
    <Box color="whiteAlpha.800" py="50px">
      <Container maxW="full" color="black">
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={[2, 4, 6, 8]}>
          {loading ? renderSkeleton() : renderJobs()}
        </SimpleGrid>
      </Container>
    </Box>
  )
}
