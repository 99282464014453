import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import FilterSec from "../components/filter"
import JobList from "../components/jobList"

const Careers = () => (
  <Layout>
    <Breadcrumb
      description="Xord Solutions perceive blockchain as a source that constitutes infinite possibilities that exceed further from the mainstream use cases. We build blockchain products and give solutions along with the development of unique projects."
      title="Careers"
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <FilterSec />
    <JobList />
  </Layout>
)

export const Head = () => <Seo title="Home" />

export default Careers
