import React from "react"
import { Box, Text, Stack, useColorModeValue, WrapItem } from "@chakra-ui/react"
import { Link } from "gatsby"
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react"

export default function SkeletonJobItem() {
  return (
    <Box to="" py={6} width="100%">
      <Box
        bg={useColorModeValue("white", "gray.900")}
        border="1px solid #eee"
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Stack>
          <Text
            as="h3"
            color="gray.600"
            fontSize={"2xl"}
            fontFamily={"body"}
            fontWeight="600"
            height="50px"
          >
            <SkeletonText mt="4" noOfLines={1} spacing="4" />
          </Text>
          <Text
            as="p"
            color="gray.600"
            fontSize={"18px"}
            fontFamily={"body"}
            height="50px"
          >
            <SkeletonText mt="4" noOfLines={1} spacing="4" width="70px" />
          </Text>
          <Text
            color={"green.500"}
            textTransform={"uppercase"}
            fontWeight={800}
            fontSize={"sm"}
            letterSpacing={1.1}
          >
            <SkeletonText mt="4" noOfLines={1} spacing="4" width="100px" />
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}
