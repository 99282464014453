import React from "react"
import { Container, Box, Text } from "@chakra-ui/react"
import Filter from "./components/filter"

export default function FilterSec() {
  return (
    <Box bg="#0a0a0a" color="whiteAlpha.800" py="50px">
      <Container maxW="full">
        <Box textAlign="center">
          <Text as="h3" color="#0FA88A" fontSize="20px" fontWeight="600">
            ACTIVE OFFERS
          </Text>
          <Text as="h2" fontSize="36px" fontWeight="600" my="3">
            Open Positions
          </Text>
          <Text as="">
            We've come a long way, but there are endless possibilities. Take a
            <br />
            look at our current openings to find out where you can get started.
          </Text>
        </Box>

        <Filter />
      </Container>
    </Box>
  )
}
