import React from "react"
import {
  Box,
  Text,
  Flex,
  Menu,
  MenuButton,
  HStack,
  Avatar,
  VStack,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react"
import { FiChevronDown } from "react-icons/fi"

const MenuComponent = ({ title }) => {
  return (
    <Menu>
      <MenuButton
        borderRight="1px solid #eeeeee47"
        py={3}
        px={5}
        transition="all 0.3s"
        _focus={{ boxShadow: "none" }}
      >
        <HStack>
          <VStack
            display={{ base: "none", md: "flex" }}
            alignItems="flex-start"
            spacing="1px"
            ml="2"
          >
            <Text fontSize="sm">{title}</Text>
          </VStack>
          <Box display={{ base: "none", md: "flex" }}>
            <FiChevronDown />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList bg="white" color="black">
        <MenuItem>Profile</MenuItem>
        <MenuItem>Settings</MenuItem>
        <MenuItem>Billing</MenuItem>
      </MenuList>
    </Menu>
  )
}
export default function Filter() {
  return (
    <Box pt="20px">
      <Flex
        alignItems={"center"}
        justifyContent="center"
        border="1px solid #eeeeee47"
        width="fit-content"
        m="auto"
      >
        <Text
          as="p"
          py={3}
          px={5}
          fontWeight="600"
          borderRight="1px solid #eeeeee47"
        >
          FILTERS
        </Text>
        <MenuComponent title="Department" />
        <MenuComponent title="Division" />
        <MenuComponent title="Job_Type" />
        <MenuComponent title="Location" />
      </Flex>
    </Box>
  )
}
